<template>
    <user-dashboard-layout :loading="loading">
        <v-container>
            <v-row dense>
                <v-chip-group column active-class="primary--text">
                    <v-chip
                        exact
                        v-for="(tag, index) in tags"
                        @click="$router.push({query: {tag: tag.name}})"
                        :key="index"
                        v-text="tag.name"/>
                </v-chip-group>
            </v-row>
            <v-row>
                <timeframe-select/>
            </v-row>
            <v-row dense class="masonry">
                <v-col class="info-grid-card mx-5" sm="12" md="6" xl="2" v-for="(item, i) in infographics" :key="i">
                    <infographic-card @loaded="resetMasonry" :infographic="item"/>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import Infographic from "../../../models/Infographic";
    import InfographicCard from "../../../components/cards/InfographicCard";
    import Tag from "@/models/Tag";
    import Masonry from 'masonry-layout'
    import TimeframeSelect from "@/views/partials/TimeframeSelect";

    export default {
        name: "Index",
        components: {TimeframeSelect, UserDashboardLayout, InfographicCard},
        data: function () {
            return {
                loading:  false,
                infographics: [],
                tags: [],
                masonry: null
            }
        },
        async mounted() {
            this.loading = true
            this.tags = await Tag.where('has_infographics', true).get();
            await this.getInfographics();
            this.loading = false;

            this.masonry = new Masonry('.masonry', {itemSelector: ".info-grid-card"});
        },
        methods: {
            resetMasonry() {
                this.$nextTick().then(() => this.masonry.layout());
            },

            async getInfographics() {
                let query = Infographic;

                this.infographics = query.where('industry_id', this.$auth.user().industry_id);

                if (this.$route.query.lastDays) {
                    this.infographics.where('lastDays', this.$route.query.lastDays);
                }

                this.infographics = this.$route.query.tag
                    ? await this.infographics.where('has_tag', this.$route.query.tag).include(['tags']).get()
                    : await this.infographics.include(['tags']).get()
            },
            removeChip() {
                this.$router.replace({'query': null});
            }
        },
        watch: {
            "$route.query": async function (val, oldVal) {
                if (val !== oldVal) {
                    await this.getInfographics();
                }
            }
        }
    }
</script>

<style scoped>

</style>
