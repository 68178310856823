<template>
    <v-card class="mx-auto mt-4" min-height="200px" @click="$router.push({name: 'dashboard.infographics.show', params: {id: infographic.id}})">
        <v-img :src="infographic.files" @load="$emit('loaded')" min-height="200px"/>
    </v-card>
</template>

<script>
    export default {
        name: 'InfographicCard',
        props: {
            infographic: {
                type: Object,
                required: true
            }
        },
    }
</script>
